import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p className="contact-tag">
          <b>Get in touch</b>
          <br />
          <br />
          info@lorrisautomation.com
        </p>
      </header>
    </div>
  );
}

export default App;
